import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthService } from '@app/shared/services/auth.service';
import { WindowToken } from '@app/shared/window.token';
import { Observable } from 'rxjs';
import { OrgBaseGuard } from './org-base.guard';
import { OrgInfo } from '@app/account/account-api.model';

@Injectable({
  providedIn: 'root',
})
export class BulkUploadGuard extends OrgBaseGuard {
  constructor(
    authService: AuthService,
    router: Router,
    @Inject(WindowToken) windowRef: Window
  ) {
    super(authService, windowRef, router);
    this.routeDirectory = '/fileupload';
  }

  public canUpload(additionalPermissions: boolean = true) {
    return this.hasPermission && additionalPermissions;
  }

  override get hasPermission() {
    const { settings, permissions } =
      this.orgInfo || ({ settings: {}, permissions: {} } as OrgInfo);

    return (
      !settings.disableBulkUpload &&
      !settings.isClientProvider &&
      !settings.skillAnalyticsClient &&
      !settings.skillInventoryClient &&
      this.authUser.canBulkUpload &&
      (permissions.uploadCompletions ||
        permissions.uploadContent ||
        permissions.uploadGroups ||
        permissions.uploadOpportunities ||
        permissions.uploadRequiredLearning ||
        permissions.uploadUserUpdates ||
        permissions.uploadClientUser ||
        permissions.uploadRoles ||
        permissions.uploadSkills)
    );
  }

  override canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return (
      this.hasPermission ||
      this.router.parseUrl(this.resolveOrgUrl('/forbidden'))
    );
  }
}
